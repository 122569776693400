import SlideShowGallery_SlideShowCleanAndSimpleComponent from '../components/NativeGalleries/SlideShowGallery/viewer/skinComps/SlideShowCleanAndSimple/SlideShowCleanAndSimple.skin';
import SlideShowGallery_SlideShowCleanAndSimpleController from '../components/NativeGalleries/SlideShowGallery/viewer/SlideShowGallery.controller';


const SlideShowGallery_SlideShowCleanAndSimple = {
  component: SlideShowGallery_SlideShowCleanAndSimpleComponent,
  controller: SlideShowGallery_SlideShowCleanAndSimpleController
};


export const components = {
  ['SlideShowGallery_SlideShowCleanAndSimple']: SlideShowGallery_SlideShowCleanAndSimple
};

